<template>
    <section>
        <header class="uk-flex uk-margin-bottom">
            <h3 class="search-overlay uk-margin-remove uk-flex-auto">requirements</h3>
            <button class="search-overlay  uk-flex-none" uk-search-icon uk-toggle="target: .search-overlay; animation: uk-animation-fade"  v-on:click="search = ''">
            </button>
            <div class="search-overlay uk-navbar-left uk-flex-1" hidden>
                <div class="uk-width-expand">
                    <form class="uk-search uk-search-navbar uk-width-1-1" v-on:submit.prevent>
                        <input class="uk-search-input" v-model="search" type="search" placeholder="search..." autofocus>
                    </form>
                </div>
                <button class="" uk-close uk-toggle="target: .search-overlay; animation: uk-animation-fade" v-on:click="search = null">

                </button>
            </div>
        </header>
        <ul class="uk-list uk-margin-left uk-margin-remove-top">
            <li  v-for="(item, index) in items" :key="index" class="uk-flex uk-flex-middle">
                <i uk-icon="icon: chevron-double-right; ratio: 1.5" class="uk-margin-small-right" v-if="!item.selected"></i>
                <article class="uk-comment">
                    <header class="uk-comment-header uk-grid-medium uk-flex-middle uk-margin-small-bottom" uk-grid>
                        <div class="uk-width-expand">
                            <h4 class="uk-comment-title uk-margin-remove">{{ item.name }} <span class="uk-text-meta">{{ item.version }}.{{ item.label }}</span></h4>
                            <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                <li v-if="item.path"><router-link :to="{ name: 'canvas', params: { path: item.path }, query: {label: item.name, version: item.version}}">Model</router-link></li>
                                <li v-if="item.url"><a :href="item.url">Wiki</a></li>
                                <template v-if="links.hasManageDependency">
                                <li v-if="item.selected && item.latest !== item.version">
                                    <button class="uk-button-link" uk-icon="push" uk-tooltip="Update requirement" v-on:click="update(item)"></button>
                                </li>
                                <li v-if="!item.selected">
                                    <button class="uk-button-link" uk-icon="bookmark" uk-tooltip="Add requirement" v-on:click="add(item)"></button>
                                </li>
                                <li v-else>
                                    <button class="uk-button-link" uk-icon="trash" uk-tooltip="Remove requirement" v-on:click="remove(item)"></button>
                                </li>
                                </template>
                            </ul>
                        </div>
                    </header>
                    <div class="uk-comment-body">
                    <p>{{ item.description }}</p>
                    </div>
                </article>
            </li>
        </ul>
        <div ref="manage" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                <h2 class="uk-modal-title">manage requirement</h2>
                <FormManage :wiki="wiki" :path="path" :artifact="selected" :links="links" :action="action" v-on:ok="afterAction()" v-on:cancel="toggle()">
                </FormManage>
                </div>
            </div>
        </div>
        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle uk-margin-bottom"
            v-if="error">
            <span class="uk-text-meta uk-text-right">
                {{ error }}
            </span>
            <i class="uk-margin-small-left" uk-icon="icon: warning; ratio: 1"></i>
        </div>
        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle"
            v-else-if="active || searchIsDirty">
            <span class="uk-margin-small-right" uk-spinner="ratio: 1"></span>
            <span class="uk-text-meta uk-text-right">
                {{ searchIsDirty ? 'searching requirements' : 'loading requirements' }}...
            </span>
        </div>
        <div class="uk-text-meta uk-text-center uk-margin-remove-top uk-margin-bottom" v-else-if="!items.length">
            <i class="uk-icon" uk-icon="icon: info"></i>
            no requirements found
        </div>
        <footer class="uk-flex uk-flex-center">
            <button class="uk-button uk-button-default uk-border-pill "
                v-if="this.results && this.results.hasNext && !active && !searchIsDirty"
                v-on:click="loadMore()">
            load more
            </button>
        </footer>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { modal } from 'uikit';
import CustomerService from '@/sdk/consumer.js';

import FormManage from '@/components/FormManage.vue';

export function debounce(fn, delay, ...args) {
  let timeoutID = null;
  return function schedule() {
    clearTimeout(timeoutID);
    const that = this;
    timeoutID = setTimeout(() => fn.apply(that, args), delay);
  };
}

export default {
    name: 'ProjectDependencies',
    props: {
        wiki: String,
        path: String,
        requirements: Array,
        links: CustomerService
    },
    components: {
        FormManage,
    },
    data() {
        var items = [];
        var dependencies = new Set();
        this.requirements.forEach(item => {
            items.push({...item, selected: true});
            dependencies.add(item.artifact_id);
        });
        return {
            searchIsDirty: false,
            search: null,
            action: null,
            selected: null,
            active: false,
            error: null,
            results: null,
            items,
            dependencies,
        };
    },
    mounted() {
    },
    methods: {
        add(item) {
            this.action = 'add';
            this.toggle(item);
        },
        update(item) {
            this.action = 'update';
            this.toggle(item);
        },
        remove(item) {
            this.action = 'remove';
            this.toggle(item);
        },
        toggle(item) {
            if (item) {
                this.selected = item;
                modal(this.$refs.manage).show();
            } else {
                modal(this.$refs.manage).hide();
                this.action = null;
            }
        },
        async loadMore() {
            if (this.results && this.results.hasNext) {
                this.active = true;
                try {
                    await this.results.loadMoreAsync();
                    this.items.push.apply(this.items, this.results.rows);
                } catch (err) {
                    this.error = err.message ?? 'An unexpected error occurred';
                } finally {
                    this.active = false;
                }
            } else if (!this.results) {
                this.active = true;
                try {
                    this.results = await this.links.asListPublishedUri()
                        .withParams(this.search)
                        .getAsync()
                        .then(results => results.map(x => { return {...x, selected: this.dependencies.has(x.artifact_id), service: this.links.from(x)}}));
                    this.items.push.apply(this.items, this.results.rows);
                } catch (err) {
                    this.error = err.message ?? 'An unexpected error occurred';
                    if (!err.status) {
                        console.error(err);
                    } else {
                        var tree = this.$error(err.status, err.error);
                        if (tree.assert_claimed()) {
                        this.error = 'the form cannot display an important error';
                        }
                    }
                } finally {
                    this.active = false;
                }
            }
        },
        debouncedSearch: debounce(function offsetSearch() {
            if (this.searchIsDirty && !this.active) {
                this.items = [];
                this.results = null;
                this.error = null;
                this.searchIsDirty = false;
                if (this.links.hasListPublished) {
                    this.loadMore();
                } else {
                    this.error = "the project list is unavailable";
                }
            } else if (this.searchIsDirty) {
                this.debouncedSearch();
            }
        }, 460),
        afterAction() {
            try {
                modal(this.$refs.manage).hide();
            } finally {
                this.selected = null;
                this.search = null;
                this.searchIsDirty = false;
                this.action = null;
                this.$emit('changed');
            }
        },
    },
    computed: {
        ...mapState('api', ['help']),
    },
    watch: {
        search() {
            if (this.search === null) {
                this.items = [];
                this.requirements.forEach(item => {
                    this.items.push({...item, selected: true});
                });
                return;
            }

            this.searchIsDirty = true;
            this.debouncedSearch();
        },
        requirements() {
            this.items = [];
            this.dependencies.clear();
            this.requirements.forEach(item => {
                this.dependencies.add(item.artifact_id);
                this.items.push({...item, selected: true});
            });
        }
    },
}
</script>