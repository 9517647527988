<template>
  <form class="uk-flex uk-flex-column uk-form-stacked uk-text-left"
      v-on:submit.prevent="submit()">
      <p class="uk-margin-large-left uk-text-right">
          Expire the token. This will break all automation that depends on this key.
      </p>
      <SubmitFooter
          danger
          :error="error"
          :active="active"
          label="expire"
          cancel="go back"
          v-on:cancel="$emit('cancel')">
      </SubmitFooter>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import CustomerService from '@/sdk/consumer.js';

import SubmitFooter from '@/components/SubmitFooter.vue';

export default {
  name: 'FormExpire',
  props: {
      wiki: String,
      path: String,
      token: Object,
      links: CustomerService
  },
  components: {
    SubmitFooter,
  },
  data() {
    return {
      active: false,
      error: null,
    };
  },
  methods: {
    async submit() {
        this.active = true;
        this.error = null;
        try {
            await this.links.asExpireTokenUri().postAsync(this.token.name);
            this.$emit('ok');
        } catch (err) {
          this.error = err.message ?? 'An unexpected error occurred';
          if (!err.status) {
            console.error(err);
          } else {
            var tree = this.$error(err.status, err.error);
            if (tree.assert_claimed()) {
              this.error = 'the form cannot display an important error';
            }
          }
        } finally {
            this.active = false;
        }
    },
  },
  computed: {
    ...mapState('api', ['help']),
  },
};
</script>
