<template>
  <Lock :wiki="wiki" v-on:ready="load()">
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle" v-if="!active && !error">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <section class="uk-width-xlarge uk-padding">
        <ul class="uk-breadcrumb uk-flex-left">
          <li><router-link to="/home">home</router-link></li>
          <li><router-link :to="{ name: 'projects', params: { wiki }}">{{ wiki }}</router-link></li>
          <li><span>{{ path }}</span></li>
        </ul>
        <section class="uk-clearfix">
          <h2>{{ name }}</h2>
          <ul class="uk-align-top uk-align-right uk-list uk-list-divider uk-text-right uk-margin-small-bottom">
            <li>
              <a :href="url" uk-icon="link">wiki</a>
            </li>
            <li v-if="links.hasEdit"><router-link :to="{ name: 'canvas', params: { path: path }}">design</router-link></li>
          </ul>
          <p>{{ description }}</p>
        </section>
        <ProjectDependencies :wiki="wiki" :path="path" :links=links :requirements="requirements" v-on:changed="load()" v-if="links.hasListPublished"></ProjectDependencies>
        <ProjectArtifact :wiki="wiki" :path="path" :links=links v-if="links.hasListArtifacts"></ProjectArtifact>
        <ProjectTokens :wiki="wiki" :path="path" :links=links v-on:changed="load()" v-if="links.hasListTokens"></ProjectTokens>
      </section>
      <div class="uk-flex-1"></div>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
    <Overlay :error="error" reason="loading project" class="uk-height-viewport" v-else>
    </Overlay>
  </Lock>
</template>

<script>
import { mapState } from 'vuex';

import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import Overlay from '@/components/Overlay.vue';
import ProjectTokens from '@/components/ProjectTokens.vue';
import ProjectDependencies from '@/components/ProjectDependencies.vue';
import ProjectArtifact from '@/components/ProjectArtifact.vue';

export default {
  name: 'Project',
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
    Overlay,
    ProjectTokens,
    ProjectDependencies,
    ProjectArtifact,
  },
  props: {
    wiki: String,
    path: String,
  },
  data() {
    return {
      active: true,
      error: null,
      name: null,
      description: null,
      links: {},
      url: null,
      requirements: [],
    };
  },
  methods: {
    async load() {
      this.active = true;
      try {
          let data = await this.service.getFromJsonAsync(`/acorn/consumer/module/${this.wiki}/${this.path}`);

          this.name = data.name;
          this.description = data.description;
          this.url = data.url;
          this.requirements = data.requirements.map(x => { return {...x, service: this.service.from(x)}});
          this.links = this.service.from(data);
      } catch (err) {
          this.error = err.message ?? 'An unexpected error occurred';
      } finally {
          this.active = false;
      }
    },
  },
  computed: {
    ...mapState('api', ['help', 'service']),
  },
}
</script>