<template>
  <form class="uk-form-stacked" v-on:submit.prevent="submit(name, format, runtime, description)">
    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>name<span class="uk-text-muted"> {{ error.name }}</span></span>
      <div class="uk-form-controls uk-inline">
          <i class="uk-form-icon" uk-icon="icon: database"></i>
          <input
            class="uk-input"
            type="text"
            v-model="name"
            placeholder="enter the unique key name"
            autofocus
            required
            v-bind:disabled="active"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off">
      </div>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Enter a short, memorable name for the key.
      </p>
    </label>
    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>format<span class="uk-text-muted"> {{ error.format }}</span></span>
      <select class="uk-form-controls uk-inline uk-select" v-model="format" v-bind:disabled="active" required>
          <option value="" disabled>-- select format --</option>
          <option value="client">client</option>
          <option value="server">server</option>
          <option value="module">module</option>
      </select>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Select the application format generated by this key.
      </p>
    </label>

    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>runtime<span class="uk-text-muted"> {{ error.runtime }}</span></span>
      <select class="uk-form-controls uk-inline uk-select" v-model="runtime" v-bind:disabled="active" required>
          <option value="" disabled>-- select runtime --</option>
          <option value="python3">Python 3</option>
          <option value="dotnet5">DotNET 5</option>
          <template v-if="format==='client'">
          <option value="javascript">Javascript</option>
          <option value="typescript">Typescript</option>
          </template>
      </select>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Select the programming language and runtime generated by this key.
      </p>
    </label>

    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>description<span class="uk-text-muted"> {{ error.description }}</span></span>
      <textarea
        class="uk-form-controls uk-inline
                uk-textarea uk-height-small"
        style="resize: vertical"
        v-model="description"
        placeholder="describe the key usage"
        required
        v-bind:disabled="active">
      </textarea>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade" v-if="help">
        Enter a brief summary of the intended use of the key. This summary
        helps distinguish between the available tokens.
      </p>
    </label>

    <SubmitFooter
      :error="error.msg"
      :active="active"
      label="setup"
      cancel="cancel"
      v-on:cancel="$emit('cancel')">
    </SubmitFooter>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import CustomerService from '@/sdk/consumer.js';

import SubmitFooter from '@/components/SubmitFooter.vue';

export default {
  name: 'FormCreate',
  components: {
    SubmitFooter,
  },
  props: {
    wiki: String,
    path: String,
    links: CustomerService
  },
  data: function initialData() {
    return {
      active: false,
      name: null,
      format: "",
      runtime: "",
      description: null,
      error: {
        msg: null,
        name: null,
        format: null,
        runtime: null,
        description: null,
      },
    };
  },
  methods: {
    async submit(name, format, runtime, description) {
      this.active = true;
      this.error = {
        msg: null,
        name: null,
        format: null,
        runtime: null,
        description: null,
      };

      try {
        let token = await this.links.asCreateTokenUri().postAsync(name, format, runtime, description);
        this.$emit('ok', token);
      } catch (err) {
        this.error.msg = err.message ?? 'An unexpected error occurred';
        if (!err.status) {
          console.error(err);
        } else {
          var tree = this.$error(err.status, err.error);
          this.error.name = tree.claim('/name');
          this.error.format = tree.claim('/format');
          this.error.runtime = tree.claim('/runtime');
          this.error.description = tree.claim('/description');
          if (tree.isCausedBy('data.5600')) {
            this.error.name = 'already exists';
          } else if (tree.assert_claimed()) {
            this.error.msg = 'the form cannot display an important error';
          }
        }
      } finally {
          this.active = false;
      }
    },
  },
  computed: {
    ...mapState('api', ['help']),
  },
  watch: {
    format() {
      this.runtime = "";
    }
  }
};
</script>
