<template>
  <form class="uk-form-stacked" v-on:submit.prevent="submit(name)">
    <p class="uk-margin-large-left uk-text-right">
        Create a copy of the artifact under a new tag name.
    </p>
    <label class="uk-flex uk-flex-column uk-margin-bottom">
      <span>name<span class="uk-text-muted"> {{ error.name }}</span></span>
      <div class="uk-form-controls uk-inline">
          <i class="uk-form-icon" uk-icon="icon: list"></i>
          <input
            class="uk-input"
            type="text"
            v-model="name"
            pattern="[a-z](?:_?[a-z0-9]+)*"
            placeholder="enter the tag name"
            autofocus
            required
            v-bind:disabled="active"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
            autocomplete="off">
      </div>
      <p class="uk-margin-large-left uk-margin-small-top uk-text-right uk-text-meta
            uk-margin-remove-bottom uk-animation-fade">
        Enter a short, memorable name for the artifact. This name is used to generate
        URLs, so short and simple is best. The name must start with a lowercase letter
        ("a" to "z"), and can contain lowercase letters, numbers ("0" to "9"), with
        an underscore ("_") between words.
      </p>
    </label>

    <SubmitFooter
      :error="error.msg"
      :active="active"
      label="clone"
      cancel="cancel"
      v-on:cancel="$emit('cancel')">
    </SubmitFooter>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import CustomerService from '@/sdk/consumer.js';

import SubmitFooter from '@/components/SubmitFooter.vue';

export default {
  name: 'FormClone',
  props: {
    wiki: String,
    path: String,
    artifact: Object,
    links: CustomerService
  },
  components: {
    SubmitFooter,
  },
  data: function initialData() {
    return {
      active: false,
      name: null,
      error: {
        msg: null,
        name: null,
      },
    };
  },
  methods: {
    async submit(name) {
      this.active = true;
      this.error = {
        msg: null,
        name: null,
      };
      try {
          await this.links.asCloneArtifactUri().postAsync(this.artifact.artifact_id, name);
          this.$emit('ok');
      } catch (err) {
        this.error.msg = err.message ?? 'An unexpected error occurred';
        if (!err.status) {
          console.error(err);
        } else {
          var tree = this.$error(err.status, err.error);
          this.error.name = tree.claim('/name');
          if (tree.assert_claimed()) {
            this.error.msg = 'the form cannot display an important error';
          }
        }
      } finally {
          this.active = false;
      }
    },
  },
  computed: {
    ...mapState('api', ['help']),
  },
};
</script>
