<template>
  <form class="uk-flex uk-flex-column uk-form-stacked uk-text-left"
      v-on:submit.prevent="submit()">
      <p class="uk-margin-large-left uk-text-right" v-if="action === 'remove'">
          Delete the dependency on this requirement. This action will remove the
          wiki redirects for this artifact that were created under <strong>{{path}}</strong>.
      </p>
      <p class="uk-margin-large-left uk-text-right" v-else-if="action === 'update'">
          Update the dependency on this requirement. This action will update from <strong>{{artifact.version}}.{{artifact.label}}</strong>
          to <strong>{{version}}.{{artifact.label}}</strong> and remove any
          conflicting requirements.
      </p>
      <p class="uk-margin-large-left uk-text-right" v-else>
          Add a dependency on this requirement. This action will create
          wiki redirects for this artifact under <strong>{{path}}</strong>, and remove any
          conflicting requirements.
      </p>
      <dl class="uk-description-list uk-text-right" v-if="artifact">
        <dt>project</dt>
        <dd>{{ artifact.name }} [{{ artifact.path }}]</dd>
        <dt>artifact</dt>
        <dd>{{ version }}.{{ artifact.label }}</dd>
      </dl>
      <SubmitFooter
          :danger="action === 'remove'"
          :error="error"
          :active="active"
          :label="action"
          cancel="go back"
          v-on:cancel="$emit('cancel')">
      </SubmitFooter>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import CustomerService from '@/sdk/consumer.js';

import SubmitFooter from '@/components/SubmitFooter.vue';

export default {
  name: 'FormManage',
  props: {
      wiki: String,
      path: String,
      action: String,
      artifact: Object,
      links: CustomerService
  },
  components: {
    SubmitFooter,
  },
  data() {
    return {
      active: false,
      error: null,
    };
  },
  methods: {
    async submit() {
        this.active = true;
        this.error = null;
        try {
            await this.links.asManageDependencyUri().postAsync(
              this.artifact.path,
              this.version,
              this.action !== 'remove');
            this.$emit('ok');
        } catch (err) {
          this.error = err.message ?? 'An unexpected error occurred';
          if (!err.status) {
            console.error(err);
          } else {
            var tree = this.$error(err.status, err.error);
            if (tree.assert_claimed()) {
              this.error = 'the form cannot display an important error';
            }
          }
        } finally {
            this.active = false;
        }
    },
  },
  computed: {
    version() {
      return this.action === 'update' ? this.artifact.latest : this.artifact.version;
    },
    ...mapState('api', ['help']),
  },
};
</script>
