<template>
    <section>
        <h3>artifacts</h3>
        <ul class="uk-list uk-margin-left uk-margin-remove-top">
            <li  v-for="(item, index) in items" :key="index">
                <article class="uk-comment">
                    <header class="uk-comment-header uk-grid-medium uk-flex-middle uk-margin-small-bottom" uk-grid>
                        <div class="uk-width-expand">
                            <h4 class="uk-comment-title uk-margin-remove">{{ item.version }}.{{ item.label }}</h4>
                            <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                <li><router-link :to="{ name: 'canvas', params: { path: path }, query: {label: item.label, version: item.version.toString()}}">Model</router-link></li>
                                <li><router-link :to="{ name: 'code', params: { path: path, label: item.label }, query: {version: item.version}}">Code</router-link></li>
                                <li v-if="item.url"><a :href="item.url">Wiki</a></li>
                                <template v-else-if="item.service">
                                <li v-if="item.service.hasReleaseArtifact"><router-link :to="{ name: 'release', params: { path: path, label: item.label }}">Release</router-link></li>
                                <li v-if="item.service.hasCloneArtifact"><button class="uk-button-link" uk-icon="git-fork" uk-tooltip="Clone artifact" v-on:click="confirmClone(item)"></button></li>
                                <li v-if="item.service.hasDeleteArtifact"><button class="uk-button-link" uk-icon="trash" uk-tooltip="Delete artifact"  v-on:click="confirmDelete(item)"></button></li>
                                </template>
                            </ul>
                        </div>
                    </header>
                    <div class="uk-comment-body">
                        <p>{{ item.description }}</p>
                    </div>
                </article>
            </li>
        </ul>
        <div ref="clone" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                <h2 class="uk-modal-title">clone artifact</h2>
                <FormClone :wiki="wiki" :path="path" :artifact="selected" :links="links" v-on:ok="afterAction('clone')"></FormClone>
                </div>
            </div>
        </div>
        <div ref="delete" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                <h2 class="uk-modal-title">delete artifact</h2>
                <FormDelete :wiki="wiki" :path="path" :artifact="selected" :links="links" v-on:ok="afterAction('delete')"></FormDelete>
                </div>
            </div>
        </div>
        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle uk-margin-bottom"
            v-if="error">
            <span class="uk-text-meta uk-text-right">
                {{ error }}
            </span>
            <i class="uk-margin-small-left" uk-icon="icon: warning; ratio: 1"></i>
        </div>
        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle"
            v-else-if="active">
            <span class="uk-margin-small-right" uk-spinner="ratio: 1"></span>
            <span class="uk-text-meta uk-text-right">
                loading artifacts...
            </span>
        </div>
        <div class="uk-text-meta uk-text-center uk-margin-remove-top" v-else-if="!items.length">
            <i class="uk-icon" uk-icon="icon: info"></i>
            no artifacts found
        </div>
        <footer class="uk-flex uk-flex-center">
            <button class="uk-button uk-button-default uk-border-pill "
                v-if="results && results.hasNext && !active"
                v-on:click="loadMore()">
            load more
            </button>
        </footer>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { modal } from 'uikit';
import CustomerService from '@/sdk/consumer.js';

import FormClone from '@/components/FormClone.vue';
import FormDelete from '@/components/FormDelete.vue';

export default {
    name: 'ProjectArtifact',
    props: {
        wiki: String,
        path: String,
        links: CustomerService
    },
    components: {
        FormClone,
        FormDelete,
    },
    data() {
        return {
            selected: null,
            active: false,
            error: null,
            items: [],
            results: null,
        };
    },
    mounted() {
        this.loadMore();
    },
    methods: {
        async loadMore() {
            this.active = true;
            if (this.results && this.results.hasNext) {
                this.active = true;
                try {
                    await this.results.loadMoreAsync();
                    this.items.push.apply(this.items, this.results.rows);
                } catch (err) {
                    this.error = err.message ?? 'An unexpected error occurred';
                } finally {
                    this.active = false;
                }
            } else if (!this.results) {
                this.active = true;
                try {
                    this.results = await this.links.asListArtifactsUri()
                        .getAsync()
                        .then(results => results.map(x => { return {...x, service: this.links.from(x)}}));
                    this.items.push.apply(this.items, this.results.rows);
                } catch (err) {
                    this.error = err.message ?? 'An unexpected error occurred';
                    if (!err.status) {
                        console.error(err);
                    } else {
                        var tree = this.$error(err.status, err.error);
                        if (tree.assert_claimed()) {
                        this.error = 'the form cannot display an important error';
                        }
                    }
                } finally {
                    this.active = false;
                }
            }
        },
        confirmClone(item) {
            this.selected = item;
            modal(this.$refs.clone).show();
        },
        confirmDelete(item) {
            this.selected = item;
            modal(this.$refs.delete).show();
        },
        afterAction(action) {
            try {
                modal(this.$refs[action]).hide();
            } finally {
                this.selected = null;
                this.items = [];
                this.results = null;
                this.loadMore();
            }
        },
    },
    computed: {
        ...mapState('api', ['help']),
    },
}
</script>