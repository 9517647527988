<template>
    <section>
        <header class="uk-flex uk-margin-bottom">
            <h3 class="search-overlay uk-margin-remove uk-flex-auto">tokens</h3>
            <button class="search-overlay  uk-flex-none" uk-icon="plus" v-on:click="create()" v-if="links.hasCreateToken">
            </button>
        </header>
        <ul class="uk-list uk-margin-left uk-margin-remove-top">
            <li  v-for="(item, index) in items" :key="index">
                <article class="uk-comment">
                    <header class="uk-comment-header uk-grid-medium uk-flex-middle uk-margin-small-bottom" uk-grid>
                        <div class="uk-width-expand">
                            <h4 class="uk-comment-title uk-margin-remove">{{ item.name }} <span class="uk-text-meta">{{ item.runtime }} {{ item.format }}</span></h4>
                            <ul class="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                                <li v-if="item.format === 'client'"><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:SDK">wiki</a></li>
                                <li v-else-if="item.format === 'server'"><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:Platform">wiki</a></li>
                                <li v-else-if="item.format === 'module'"><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:Library">wiki</a></li>
                                <li v-else><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:Quickstart">wiki</a></li>
                                <li v-if="links.hasExpireToken"><button class="uk-button-link" uk-icon="trash" uk-tooltip="Delete token" v-on:click="confirmDelete(item)"></button></li>
                            </ul>
                        </div>
                    </header>
                    <div class="uk-comment-body">
                        <p>{{ item.description }}</p>
                    </div>
                </article>
            </li>
        </ul>
        <div ref="delete" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                <h2 class="uk-modal-title">expire token</h2>
                <FormExpire :wiki="wiki" :path="path" :token="selected" :links="links" v-on:ok="afterAction('delete')"></FormExpire>
                </div>
            </div>
        </div>
        <div ref="create" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                <h2 class="uk-modal-title">create token</h2>
                <FormCreate :wiki="wiki" :key="key" :path="path" :links="links" v-on:ok="afterAction('create', $event)"></FormCreate>
                </div>
            </div>
        </div>
        <div ref="token" uk-modal>
            <div class="uk-modal-dialog">
                <div class="uk-modal-body">
                    <h2 class="uk-modal-title">token created</h2>
                    <p>Record this token in a safe place, as this is the only time it will be visible.</p>
                    <dl class="uk-description-list" v-if="selected">
                        <dt>name</dt>
                        <dd>{{ selected.name }}</dd>
                        <dt>token</dt>
                        <dd>{{ selected.token }}</dd>
                        <dt>format</dt>
                        <dd>{{ selected.format }}</dd>
                        <dt>runtime</dt>
                        <dd>{{ selected.runtime }}</dd>
                        <dt>description</dt>
                        <dd>{{ selected.description }}</dd>
                        <dt>learn more</dt>
                        <dd v-if="selected.format === 'client'"><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:SDK">Embedding your SDK</a></dd>
                        <dd v-else-if="selected.format === 'server'"><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:Platform">Developing your platform</a></dd>
                        <dd v-else-if="selected.format === 'module'"><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:Library">Creating a library</a></dd>
                        <dd><a href="https://www.squirreltechnologies.nz/wiki/Walkthrough:Quickstart">Getting started</a></dd>
                    </dl>
                </div>
            </div>
        </div>
        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle uk-margin-bottom"
            v-if="error">
            <span class="uk-text-meta uk-text-right">
                {{ error }}
            </span>
            <i class="uk-margin-small-left" uk-icon="icon: warning; ratio: 1"></i>
        </div>
        <div class="uk-text-muted uk-flex uk-flex-center uk-flex-middle"
            v-else-if="active">
            <span class="uk-margin-small-right" uk-spinner="ratio: 1"></span>
            <span class="uk-text-meta uk-text-right">
                loading tokens...
            </span>
        </div>
        <div class="uk-text-meta uk-text-center uk-margin-remove-top" v-else-if="!items.length">
            <i class="uk-icon" uk-icon="icon: info"></i>
            no tokens found
        </div>
        <footer class="uk-flex uk-flex-center">
            <button class="uk-button uk-button-default uk-border-pill "
                v-if="this.results && this.results.hasNext && !active"
                v-on:click="loadMore()">
            load more
            </button>
        </footer>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { modal, notification } from 'uikit';
import CustomerService from '@/sdk/consumer.js';

import FormExpire from '@/components/FormExpire.vue';
import FormCreate from '@/components/FormCreate.vue';

export default {
    name: 'ProjectTokens',
    props: {
        wiki: String,
        path: String,
        links: CustomerService
    },
    components: {
        FormExpire,
        FormCreate,
    },
    data() {
        return {
            selected: null,
            active: false,
            error: null,
            items: [],
            results: null,
            key: 0,
        };
    },
    mounted() {
        return this.loadMore();
    },
    methods: {
        async loadMore() {
            this.active = true;
            if (this.results && this.results.hasNext) {
                this.active = true;
                try {
                    await this.results.loadMoreAsync();
                    this.items.push.apply(this.items, this.results.rows);
                } catch (err) {
                    this.error = err.message ?? 'An unexpected error occurred';
                } finally {
                    this.active = false;
                }
            } else if (!this.results) {
                this.active = true;
                try {
                    this.results = await this.links.asListTokensUri()
                        .getAsync()
                        .then(results => results.map(x => { return {...x, service: this.links.from(x)}}));
                    this.items.push.apply(this.items, this.results.rows);
                } catch (err) {
                    this.error = err.message ?? 'An unexpected error occurred';
                    if (!err.status) {
                        console.error(err);
                    } else {
                        var tree = this.$error(err.status, err.error);
                        if (tree.assert_claimed()) {
                            this.error = 'the form cannot display an important error';
                        }
                    }
                } finally {
                    this.active = false;
                }
            }
        },
        copy(text) {
            navigator.clipboard.writeText(text);
            notification("<span uk-icon='icon: check'></span> Copied to clipboard", {pos: 'top-center', status:'success'});
        },
        confirmDelete(item) {
            this.selected = item;
            modal(this.$refs.delete).show();
        },
        create() {
            modal(this.$refs.create).show();
        },
        afterAction(action, event) {
            try {
                modal(this.$refs[action]).hide();
            } finally {
                this.selected = null;
                this.items = [];
                this.results = null;
            }

            if (action === 'create') {
                this.key++;
                this.selected = event;
                modal(this.$refs.token).show();
            }
            return this.loadMore();
        },
    },
    computed: {
        ...mapState('api', ['help']),
    },
}
</script>